import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import Project from "../components/Project";
import Seo from "../components/Seo";

const IronWomen = () => (
  <Layout>
    <Seo
      title={"Iron women"}
      description={
        "How Floradix became the market leader in iron supplements through integrated sales & marketing."
      }
    />
    <Project type="iron-women" />
    <Footer />
  </Layout>
);

export default IronWomen;

export const pageQuery = graphql`
  query IronWomenQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
